<template>
  <v-card>
    <v-card-title class="align-start">
      <span>{{ currentRevenueName }}</span>
      <v-tooltip
        v-if="tooltipText"
        max-width="300"
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            small
            color="primary"
            class="ml-2"
            v-bind="attrs"
            style="cursor: pointer;"
            v-on="on"
          >
            {{ icons.mdiInformationOutline }}
          </v-icon>
        </template>
        <span>{{ tooltipText }}</span>
      </v-tooltip>
    </v-card-title>

    <v-card-text
      v-show="isRevenueLoading"
    >
      <revenue-card-shimmer-vue />
    </v-card-text>

    <v-card-text
      v-show="!isRevenueLoading"
    >
      <div class="d-flex align-center">
        <h1 class="text-3xl font-weight-semibold">
          Ksh {{ currentRevenue | formatCurrency }}
        </h1>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiMenuUp, mdiMenuDown, mdiInformationOutline } from '@mdi/js'
import RevenueCardShimmerVue from './RevenueCardShimmer.vue'

export default {
  filters: {
    positive(value) {
      return Math.abs(value)
    },
  },
  components: {
    RevenueCardShimmerVue,
  },
  props: {
    isRevenueLoading: {
      type: Boolean,
      default: true,
    },
    currentRevenueName: {
      type: String,
      required: true,
    },
    currentRevenue: {
      type: Number,
      required: true,
    },
    tooltipText: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      icons: { mdiMenuUp, mdiMenuDown, mdiInformationOutline },
    }
  },
  computed: {
    differenceIcon() {
      if (this.currentRevenue > this.previousRevenue) {
        return this.icons.mdiMenuUp
      }
      if (this.currentRevenue < this.previousRevenue) {
        return this.icons.mdiMenuDown
      }

      return null
    },
    percentageDifference() {
      const difference = this.currentRevenue - this.previousRevenue
      const average = (this.currentRevenue + this.previousRevenue) / 2

      return Math.round((difference / average) * 100)
    },
  },
}
</script>
