<template>
  <v-card min-height="600">
    <v-container>
      <v-row justify="center">
        <v-col
          cols="12"
          md="6"
          class="mt-8 mb-0"
        >
          <v-autocomplete
            v-model="selectedStation"
            :items="stations"
            item-text="name"
            item-value="id"
            label="Station"
            :loading="isStationsLoading"
            outlined
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col
          cols="12"
          md="6"
        >
          <table-filter
            :table-name="'transactions'"
            default-date-picker-date="Last 30 days"
            @filter="applyFilters"
          />
        </v-col>
      </v-row>
      <v-row
        class="match-height mt-4"
      >
        <v-col>
          <revenue-card
            :current-revenue-name="'Total Revenue Received'"
            tooltip-text="The total money received by the platform"
            :current-revenue="totalRevenueReceived"
            :is-revenue-loading="isTotalRevenueReceivedLoading"
          />
        </v-col>
        <v-col>
          <revenue-card
            :current-revenue-name="'Total Operation Charges'"
            tooltip-text="The total amount deducted from clients' transactions as operation charges"
            :current-revenue="totalOperationCharges"
            :is-revenue-loading="isTotalOperationChargesLoading"
          />
        </v-col>
        <v-col>
          <revenue-card
            :current-revenue-name="'Total Withdrawn'"
            tooltip-text="The total amount client have withdrawn from their wallets"
            :current-revenue="totalWithdrawn"
            :is-revenue-loading="isTotalWithdrawnLoading"
          />
        </v-col>
        <v-col>
          <revenue-card
            :current-revenue-name="'Wallet Balance'"
            tooltip-text="The current balance of the wallet"
            :current-revenue="walletBalance"
            :is-revenue-loading="isWalletBalanceLoading"
          />
        </v-col>
      </v-row>
      <v-row justify="center">
        <h3 class="text-center mt-6 mb-2">
          Transaction History
        </h3>
        <v-data-table
          :headers="headers"
          :items="transactionHistory"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :loading="isTransactionHistoryLoading"
          disable-pagination
          hide-default-footer
        >
          <template v-slot:progress>
            <v-progress-linear
              indeterminate
              class="mt-2"
              rounded
            ></v-progress-linear>
            <p class="text-center mt-1">
              Loading...Please wait
            </p>
          </template>
          <template v-slot:body="{ items }">
            <tbody
              v-if="transactionHistory.length > 0"
            >
              <tr
                v-for="transaction in items"
                :key="transaction.transaction_id"
              >
                <td>{{ transaction.transaction_id }}</td>
                <td>{{ transaction.transaction_type === 'credit' ? 'Payment' : 'Withdrawal' }}</td>
                <td>{{ transaction.transaction_status_description }}</td>
                <td>{{ transaction.amount }}</td>
                <td>{{ transaction.account_balance_before }}</td>
                <td>{{ transaction.account_balance_after }}</td>
                <td>{{ transaction.phone_number }}</td>
                <td>{{ transaction.created_at | formatDate }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr v-show="!isTransactionsLoading">
                <td
                  :colspan="headers.length"
                  style="text-align: center"
                >
                  No transactions found
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
        <data-table-pagination
          :page="pagination.page"
          :page-count="pagination.pageCount"
          @page-change="onPageChange"
          @items-per-page-change="onItemsPerPageChange"
        />
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import axios from 'axios'
import TableFilter from '@/components/partials/table-filter/index.vue'
import RevenueCard from '@/views/statistics/transactions/components/RevenueCard.vue'
import DataTablePagination from '@/components/partials/DataTablePagination.vue'

export default {
  name: 'TransactionStatistics',
  components: {
    TableFilter,
    RevenueCard,
    DataTablePagination,
  },
  data() {
    return {
      filters: {
        dates: {
          fromDate: '',
          toDate: '',
        },
      },
      sortBy: ['created_at'],
      sortDesc: [true],
      pagination: {
        page: 1,
        pageCount: 1,
        itemsPerPage: 10,
      },
      headers: [
        {
          text: 'Transaction ID',
          value: 'transaction_id',
          sortable: true,
        },
        {
          text: 'Transaction Type',
          value: 'transaction_type',
          sortable: true,
        },
        {
          text: 'Transaction Status',
          value: 'transaction_status',
          sortable: true,
        },
        {
          text: 'Amount',
          value: 'amount',
          sortable: true,
        },
        {
          text: 'Account Balance Before',
          value: 'account_balance_before',
          sortable: true,
        },
        {
          text: 'Account Balance After',
          value: 'account_balance_after',
          sortable: true,
        },
        {
          text: 'Phone Number',
          value: 'phone_number',
          sortable: true,
        },
        {
          text: 'Date',
          value: 'created_at',
          sortable: true,
        },
      ],
      isStationsLoading: false,
      selectedStation: 'all',
      stations: [],
      transactionHistory: [],
      isTotalRevenueReceivedLoading: false,
      isTotalOperationChargesLoading: false,
      isTotalWithdrawnLoading: false,
      isTransactionHistoryLoading: false,
      isWalletBalanceLoading: false,
      totalRevenueReceived: '',
      totalOperationCharges: '',
      totalWithdrawn: '',
      walletBalance: '',
    }
  },
  watch: {
    selectedStation() {
      this.getRevenue()
      this.getWithdrawal()
      this.getTransactionHistory()
      this.getWalletBalance()
    },
  },
  mounted() {
    this.getStations()
    this.getWithdrawal()
    this.getTransactionHistory()
    this.getWalletBalance()
  },
  methods: {
    applyFilters(filters) {
      this.filters = filters
      this.getRevenue()
      this.getWithdrawal()
      this.getTransactionHistory()
      this.getWalletBalance()
    },
    getStations() {
      this.isStationsLoading = true
      axios.get('meter-stations').then(response => {
        this.stations = response.data
        this.stations.unshift({
          id: 'all',
          name: 'All Stations',
          location: 'all',
          paybill_number: 'all',
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
        })
      }).finally(() => {
        this.isStationsLoading = false
      })
    },
    getRevenue() {
      this.isTotalRevenueReceivedLoading = true
      this.isTotalOperationChargesLoading = true

      const params = {
        fromDate: this.filters.dates.fromDate,
        toDate: this.filters.dates.toDate,
        stationId: this.selectedStation,
      }

      axios
        .get('statistics/revenue', { params })
        .then(response => {
          this.totalRevenueReceived = response.data.totalRevenue
          this.totalOperationCharges = response.data.totalOperationCharges
        })
        .catch(error => {
          console.log(error)
        }).finally(() => {
          this.isTotalRevenueReceivedLoading = false
          this.isTotalOperationChargesLoading = false
        })
    },
    getWithdrawal() {
      this.isTotalWithdrawnLoading = true
      const params = {
        fromDate: this.filters.dates.fromDate,
        toDate: this.filters.dates.toDate,
        stationId: this.selectedStation,
      }
      axios
        .get('statistics/total-withdrawal', { params })
        .then(response => {
          this.totalWithdrawn = response.data.totalWithdrawn
        })
        .catch(error => {
          console.log(error)
        }).finally(() => {
          this.isTotalWithdrawnLoading = false
        })
    },
    getTransactionHistory() {
      this.isTransactionHistoryLoading = true
      const params = {
        fromDate: this.filters.dates.fromDate,
        toDate: this.filters.dates.toDate,
        stationId: this.selectedStation,
        page: this.pagination.page,
        perPage: this.pagination.itemsPerPage,
        sortBy: this.sortBy[0],
        sortOrder: this.sortDesc[0] ? 'desc' : 'asc',
      }
      axios
        .get('statistics/transaction-history', { params })
        .then(response => {
          this.transactionHistory = response.data.data
          this.pagination.page = response.data.current_page
          this.pagination.pageCount = response.data.last_page
          this.pagination.itemsPerPage = response.data.per_page
        })
        .catch(error => {
          console.log(error)
        }).finally(() => {
          this.isTransactionHistoryLoading = false
        })
    },
    getWalletBalance() {
      this.isWalletBalanceLoading = true
      axios.get(`reseller/balance?stationId=${this.selectedStation}`)
        .then(response => {
          this.walletBalance = response.data.balance
        })
        .catch(error => {
          this.$notification.error(error.response.data.message)
        })
        .finally(() => {
          this.isWalletBalanceLoading = false
        })
    },
    onPageChange(page) {
      this.pagination.page = page
      this.getTransactionHistory()
    },
    onItemsPerPageChange(itemsPerPage) {
      this.pagination.itemsPerPage = itemsPerPage
      this.getTransactionHistory()
    },
  },
}
</script>

<style scoped>
.custom-tab-items {
    background-color: transparent !important;
}
</style>
